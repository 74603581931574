:root {
    --color-primary: #3fc1be;
    --color-light: #fff;
    --color-dark: #303e67;
    --color-red: rgb(245, 50, 50);
    --color-warning: rgb(252, 166, 38);
    --radius: 20px;

    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f8f9fb;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;

    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;

    --background: linear-gradient(14deg, #3fc1be 0%, #3fc1bf6c);
    --color-shadow: 0 7px 14px 0 #3fc1bf49;
}

* {
    margin: 0;
    padding: 0;
}

*,
*::after,
a::before {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    /* font-family: "Roboto", sans-serif !important; */
    font-family: "Poppins", sans-serif !important;
    color: #656d9a !important;
}

/* General Styles */
label {
    display: block;
    margin-bottom: 1rem;
}

button {
    cursor: pointer;
}

.title {
    font-size: 1.6rem;
    margin: 0;
}

.card {
    margin-top: 2rem;
    background-color: var(--color-light);
    padding: 2rem;
    border-radius: var(--radius);

    /* overflow-x: scroll; */
}

.card__title {
    font-weight: 500;
}

.table {
    margin-top: 2rem;
    width: 100%;

    
}

.table__wrapper {
    width: 100%;
    overflow-x: scroll;
}

.table thead {
    border: 2px solid var(--color-grey-light-2);
}

.table th {
    padding: 1rem;
    text-align: left;
    white-space: nowrap;
}

.table td {
    padding: 1rem;
    border:1px solid var(--color-grey-light-3);
}

.table tr:nth-child(even) {
  background-color: var(--color-grey-light-2); }

.pagination {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.filter {
    margin-top: 2rem;
    border: 1px solid var(--color-grey-light-3);
    border-radius: var(--radius);
    padding: 1rem;
}

.label {
    margin-bottom: .5rem;
    display: block;
}