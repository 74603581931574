.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-grey-dark-2);

    span {
        flex: 0 0 50%;

        &:last-child {
            color: var(--color-primary);
        }
    }
}