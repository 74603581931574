.card {
    background-color: var(--color-light);
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--color-primary);
        margin-bottom: 2rem;

        svg {
            margin-right: 1rem;
        }
    }
}

.info {
    display: block;
    margin: .5rem 0;
}

.data {
    font-size: 2.6rem;
    font-weight: 700;
}