.info_card {
    // background-color: #80bebdd4;
    background: var(--background);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 5px;

    span {
        font-size: 1.4rem;
        font-weight: bold;
        color: white;
    }

    b {
        font-size: 2.2rem;
        // color: var(--color-primary);
    }
}