.form {
    width: 100%;
    max-width: 400px;
    height: 400px;
    background-color: var(--color-light);
    padding: 2rem 2.5rem;
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    position: relative;

    button {
        background: var(--background);
        border: none;
        border-radius: var(--radius);
        height: 35px;
        box-shadow: var(--color-shadow);
        color: var(--color-light);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        margin-top: 4rem;
        margin-bottom: 2rem;
        margin-top: auto;

        svg {
            margin-left: 1rem;
        }
    }
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #d4e9e81c 0%, #3fc1bf2a 55%);
}

.title {
    font-weight: 600;
    font-size: 2rem;
    color: var(--color-dark);
    text-align: center;
    margin-top: 3rem;
}

.subtitle {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--color-grey-dark-3);
    text-align: center;
    display: block;
    margin-top: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 0.2px;
}

.logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: -0%;
    background-color: var(--color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2.25px #f1f5fa;

    img {
        width: 55px;
        height: 55px;
    }
}

.otp {
    border: none;
    border-bottom: 1px solid var(--color-primary);
    width: 100%;

    &:focus {
        border: 1px solid var(--color-primary);
    }
}

.otp_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
