.loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
        box-shadow: none;
    }

    50% {
        transform: scale(1.05);
        // box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25);
    }

    100% {
        transform: scale(1);
        box-shadow: none;
    }
}

@keyframes floating-ball-model-3 {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}

.icon {
    width: 100px;
    // animation: pulsate 1s infinite;
    animation: floating-ball-model-3 2s infinite;
}